import { z } from 'zod';
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { hostname, orgaentityBase } from '../../core-api/types/orgaentity.type';
import { OrderStatus } from '../enums/order.enum';
import { orderBillingAddressData, orderShippingAddressData } from './order-address-data.type';
import { user } from '../../user-api/types/user.type';
extendZodWithOpenApi(z);
const userId = z.number().int().positive().nullable().readonly();
const orderHistoryItem = z.object({
    status: z.nativeEnum(OrderStatus),
    userId: userId.describe('User that caused the change / executed the action'),
    date: z.string().datetime(),
});
const orderEvent = z.object({
    status: z.string(),
    payload: z.string(),
    date: z.string().datetime(),
});
export const order = z.object({
    id: z.number().int().positive(),
    userId: userId.describe('User that created the order'),
    path: orgaentityBase.shape.path,
    created: z.string().datetime(),
    updated: z.string().datetime().nullable(),
    status: z.nativeEnum(OrderStatus),
    statusHistory: z.array(orderHistoryItem).nullable(),
    events: z.array(orderEvent).nullable(), // TODO: ?
    requestId: z.string().length(10).nullable().describe('Internal request ID to prevent duplicate orders'),
    billingData: orderBillingAddressData.nullable(),
    shippingData: orderShippingAddressData.nullable(),
    extraData: z.object({
        customInputValues: z.record(z.string(), z.string().or(z.number())).optional(),
        comment: z.string().optional().describe('Customer comment'),
        clientnames: z.array(z.string()).optional().openapi({ deprecated: true })
            .describe('Player hostnames that are included in the order. Moved to the corresponding order product (`orderProduct.extraData.hostnames`).'),
    }).nullable().openapi({ additionalProperties: true }),
    locale: z.string().length(5),
    locked: z.number().min(0).max(1),
});
export const orders = z.array(order);
const product = z.object({
    id: z.number().int().positive(),
    version: z.number().int().positive(),
    shopId: z.number().int().positive(),
    identifier: z.string().min(1).max(250),
    settings: z.any(),
    notifications: z.any(),
    abstract: z.any(),
    components: z.any(),
    integrations: z.any(),
    derivedFromId: z.number().int().positive().nullable(),
    derivedFromVersion: z.number().int().positive().nullable(),
    userId: z.number().int().positive().nullable(),
    created: z.string().datetime(),
    deleted: z.string().datetime().nullable(),
});
const orderProduct = z.object({
    shopOrderId: z.number().int().positive(),
    shopProductId: z.number().int().positive(),
    shopProductVersion: z.number().int().positive(),
    product: product.optional(), // Optional nested object.
    productName: z.string().min(1).max(250),
    quantity: z.number().int().nonnegative(),
    totalPriceNet: z.number().nonnegative(),
    totalPriceGross: z.number().nonnegative(),
    extraData: z.object({
        hostnames: z.array(hostname).optional().describe('Player hostnames that are included in this order product.'),
    }).nullable().openapi({ additionalProperties: true }),
});
export const orderWithUserAndProducts = order.extend({
    user: user.strip(),
    products: z.array(orderProduct),
});
const orderProductInput = z.object({
    shopProductId: orderProduct.shape.shopProductId,
    shopProductVersion: orderProduct.shape.shopProductVersion,
    productName: orderProduct.shape.productName,
    quantity: orderProduct.shape.quantity,
    totalPriceNet: orderProduct.shape.totalPriceNet,
    totalPriceGross: orderProduct.shape.totalPriceGross,
    extraData: orderProduct.shape.extraData,
});
export const orderUpdateInput = order.pick({
    id: true,
    status: true,
});
export const orderCreateInput = order.pick({
    userId: true,
    path: true,
    status: true,
    statusHistory: true,
    events: true,
    requestId: true,
    billingData: true,
    shippingData: true,
    extraData: true,
    locale: true,
}).extend({
    products: z.array(orderProductInput),
});

import { z } from 'zod';
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { orgaentityBillingAddress } from '../../core-api/types/orgaentity-billing.type';
extendZodWithOpenApi(z);
const orderContactAddressData = z.object({
    id: z.number().positive().optional(),
    name: z.string().min(1),
    name2: z.string().optional(),
    gssn: z.string().min(7).max(8).regex(/^\d+$/),
    street: z.string().min(1),
    city: z.string().min(1),
    zip: z.string().min(1),
    country: z.string().length(2),
    vatId: z.string().optional(),
    mail: orgaentityBillingAddress.shape.email.optional(),
    phone: z.string().optional(),
});
const orderManualAddressData = z.object({
    id: z.number().positive().optional(),
    name: z.string().min(1),
    street: z.string().min(1),
    zip: z.string().min(1),
    city: z.string().min(1),
    country: z.string().length(2),
});
export const orderBillingAddressData = orderContactAddressData;
export const orderShippingAddressData = orderContactAddressData.or(orderManualAddressData);

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  type LicenseInstanceExtended,
  licenseInstancesExtended,
  type License,
  licenses,
  type LicenseInstanceCreateInput,
} from '@dep/common/core-api/types/license.type';
import { type ListResponse } from '@dep/common/interfaces';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom } from 'rxjs';

import { UserService } from '@dep/frontend/services/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LicenseInstanceService {
  constructor(
    private readonly logger: NGXLogger,
    private readonly http: HttpClient,
    private readonly userService: UserService,
  ) { }

  /**
   * Fetch the list of license instances from the core API.
   *
   * @param data - The query parameters to filter the list of license instances
   * @returns An array of `LicenseInstanceExtended` objects representing the list of license instances fetched from the core API
   */
  public async listLicenseInstances(
    data: Record<string, any>,
  ): Promise<ListResponse<LicenseInstanceExtended>> {
    this.logger.debug('Listing license instances');

    try {
      const response = await lastValueFrom(
        this.http.get<unknown>(
          environment.config.coreApiGateway.url + '/licenses/instances',
          {
            params: data,
            headers: await this.userService.getAuthorizationHeaders(),
          },
        ),
      );

      this.logger.debug('License instances fetched successfully', response);

      // Parse the API response using `licenseInstancesExtended.parse()` to create validated `LicenseInstance` objects.
      const instances: ListResponse<LicenseInstanceExtended> = {
        items: licenseInstancesExtended.parse(response),
        count: undefined,
      };

      return instances;
    } catch (error) {
      this.logger.error('Error occurred while fetching all license instances:', error);
      throw error;
    }
  }

  /**
   * Fetch the list of licenses from the core API.
   *
   * @returns An array of `LicenseInstance` objects representing the list of license instances fetched from the core API
   */
  public async listLicenses(): Promise<License[]> {
    this.logger.debug('Listing licenses');

    try {
      const response = await lastValueFrom(
        this.http.get<unknown>(
          environment.config.coreApiGateway.url + '/licenses',
          {
            headers: await this.userService.getAuthorizationHeaders(),
          },
        ),
      );

      this.logger.debug('Licenses fetched successfully', response);

      // Parse the API response using `licenses.parse()` to create validated `Licenses` objects.
      const validatedLicenses = licenses.parse(response);

      return validatedLicenses;
    } catch (error) {
      this.logger.error('Error occurred while fetching all licenses:', error);
      throw error;
    }
  }

  /**
   * Assign licenses to orgaentities.
   *
   * @param licenseInstanceCreateInputs - License instances input data to assign licenses to orgaentities
   * @returns An array of `LicenseInstanceExtended` objects representing the list of license instances fetched from the core API
   */
  public async assignLicenses(licenseInstanceCreateInputs: LicenseInstanceCreateInput[]): Promise<LicenseInstanceExtended[]> {
    this.logger.debug('Assigning license(s)', licenseInstanceCreateInputs);

    try {
      const response = await lastValueFrom(this.http.post<unknown>(
        environment.config.coreApiGateway.url + '/licenses/instances',
        licenseInstanceCreateInputs,
        {
          headers: await this.userService.getAuthorizationHeaders(),
        },
      ));

      const validatedLicenseInstances = licenseInstancesExtended.parse(response);

      return validatedLicenseInstances;
    } catch (error) {
      this.logger.error('Error occurred while assigning license:', error);
      throw error;
    }
  }

  /**
   * Unassign a license from an orgaentity.
   *
   * @param licenseInstanceId - License instance ID that will be removed
   */
  public async unassignLicense(licenseInstanceId: number): Promise<void> {
    this.logger.debug('Unassigning license', licenseInstanceId);

    try {
      await lastValueFrom(this.http.delete(
        `${environment.config.coreApiGateway.url}/licenses/instances/${licenseInstanceId}`,
        {
          headers: await this.userService.getAuthorizationHeaders(),
        },
      ));
    } catch (error) {
      this.logger.error('Error occurred while unassigning license', error);
      throw error;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { License } from '@dep/common/core-api/types/license.type';

import { environment } from 'src/environments/environment';

@Pipe({
  name: 'licenseIcon',
  standalone: true,
})
export class LicenseIconPipe implements PipeTransform {
  /**
   * Transforms the given license into the icon path associated with the license ID.
   *
   * @param license - License to get the icon for
   * @returns The icon path associated with the given license ID
   */
  public transform(license: License): string | undefined {
    return `${environment.config.coreApiGateway.url}/files/licenses/icons/${license.iconS3Key}`;
  }
}

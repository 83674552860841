import { z } from 'zod';
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { stringToJsonSchema } from '../../utils/zod/string-to-json-schema';
import { criteriaList, sortingMultiple } from './provider-parameter.type';
import { order } from '../../shop-api/types/order.type';
import { orgaentityBase, orgaentityClientPure } from './orgaentity.type';
extendZodWithOpenApi(z);
export const license = z.object({
    id: z.number().int().positive().describe('Internal license ID.'),
    identifier: z.string().min(3)
        .describe('Technical identifier for the license. Can be used for references where the ID cannot be used.')
        .openapi({ examples: ['DIGITALSIGNAGE', 'SHOWROOMCONNECTSMALL'] }),
    name: z.string().min(3)
        .describe('Name. Can be displayed to end users.')
        .openapi({ examples: ['Digital:Signage', 'Showroom:Connect Small'] }),
    iconS3Key: z.string().length(20).describe('S3 key of the license icon.'),
    numberOfInstances: z.number().nonnegative().describe('Number of assigned instances (devices).'),
});
export const licenses = z.array(license);
const licenseInstanceData = z.object({
    id: z.number().int().positive().describe('License instance ID.'),
    expires: z.string().datetime().nullable()
        .describe('Expiration date of the license. (`null` = perpetual)')
        .openapi({ examples: ['2026-01-01T00:00:00.000Z'] }),
    canceled: z.string().datetime().nullable()
        .describe('Date when the license was canceled. Note that this does NOT represent the expiration date. Do not use this for license validity checks.')
        .openapi({ examples: ['2025-11-15T00:00:00.000Z'] }),
    lastModified: z.string().datetime()
        .describe('Date on which the license was last modified. (Creation is considered as modification.)'),
    orderIds: z.array(z.number().int().positive().describe('Order ID.')).nullish().describe('List of related (unordered) order IDs.'),
});
export const licenseInstance = z.object({
    orgaentity: z.object({
        id: orgaentityBase.shape.id,
        type: orgaentityBase.shape.type,
        name: orgaentityBase.shape.name,
        subtypeName: z.string().nullable().describe('Sub-type of the Orgaentity, e. g. "Digital:Signage", "Showroom:Presenter", or "Media Hop" for `CLIENT`s'),
        gssn: orgaentityBase.shape.gssn,
        hostname: orgaentityClientPure.shape.hostname,
    }),
}).merge(licenseInstanceData);
export const licenseInstances = z.array(licenseInstance);
export const licenseInstanceExtended = z.object({
    licenseId: license.shape.id,
    parentOrgaentity: z.object({
        id: orgaentityBase.shape.id,
        name: orgaentityBase.shape.name,
    }),
    lastOrderCreated: order.shape.created.nullable(),
}).merge(licenseInstance).describe('Extended version of a license instance, used for the DEP licenses list (with location name and last order date)');
export const licenseInstancesExtended = z.array(licenseInstanceExtended);
export const licenseInstancesListQueryParameters = z.object({
    canceled: z.enum(['true', 'false']).optional(),
    limit: z.coerce.number().int().positive().optional(),
    offset: z.coerce.number().int().nonnegative().optional(),
    sorting: stringToJsonSchema.pipe(sortingMultiple).optional(),
    filter: stringToJsonSchema.pipe(criteriaList).optional(),
});
export const licenseInstanceCreateInput = z.object({
    licenseId: licenseInstanceData.shape.id,
    orgaentityId: orgaentityBase.shape.id,
    expires: licenseInstanceData.shape.expires,
});
export const licenseInstanceCreateDatabaseInput = licenseInstanceCreateInput.transform((data) => ({
    // Rewrite keys to match database columns.
    orgaentities_id: data.orgaentityId,
    licenses_id: data.licenseId,
    expires: data.expires,
    // Add read-only default values of the license instance.
    canceled: null,
    last_modified: new Date().toISOString(),
}));
export const licenseInstanceOrderCreateDatabaseInput = z.object({
    orgaentities_licenses_id: licenseInstance.shape.id,
    shops_orders_id: order.shape.id,
});
export const licenseInstanceDeleteByAttributeInput = z.object({
    licenseInstanceId: z.coerce.number().int().positive(),
    orgaentityId: z.coerce.number().int().positive(),
    licenseId: z.coerce.number().int().positive(),
}).partial().refine(
// Only ONE of the attributes must be set (XOR).
// This is the case if `true + false + false === 1` for example.
(data) => Number(data.licenseInstanceId !== undefined) + Number(data.orgaentityId !== undefined) + Number(data.licenseId !== undefined) === 1, 'Only one license instance delete input attribute allowed.').describe('License instance delete input. Only one attribute must be set.');
export const licenseInstanceDeleteByAttributeDatabaseInput = licenseInstanceDeleteByAttributeInput.transform((data) => ({
    id: data.licenseInstanceId,
    orgaentities_id: data.orgaentityId,
    licenses_id: data.licenseId,
}));
